export const getSpacings = (
  desktopSpacing: string,
  tabletSpacing?: string,
  mobileSpacing?: string
): string | string[] => {
  const spacing =
    (mobileSpacing &&
      tabletSpacing && [
        mobileSpacing,
        tabletSpacing,
        desktopSpacing
      ]) ||
    (!mobileSpacing &&
      tabletSpacing && [
        tabletSpacing,
        tabletSpacing,
        desktopSpacing
      ]) ||
    desktopSpacing;

  return spacing;
};
