import { storyblokEditable } from "@storyblok/react";

import Spacer from "@components/layout/Spacer";

import { SpacerStoryblokProps } from "./types";
import { getSpacings } from "./utils";

const SpacerStoryblok: React.FC<SpacerStoryblokProps> = ({
  blok
}) => {
  const { desktop, tablet, mobile } = blok;

  const spacing = getSpacings(desktop, tablet, mobile);

  return <Spacer pt={spacing} {...storyblokEditable(blok)} />;
};

export default SpacerStoryblok;
